import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-book-features',
    templateUrl: './book-features.component.html',
    styleUrls: ['./book-features.component.scss']
})
export class BookFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: "What is STEM?",
            paragraphText: 'STEM is an abbreviation formed from the initial of Science, Technology, Engineering and Mathematics. STEM skills are increasingly necessary to engage in a knowledge-based economy. It was created for the lack of qualified candidates in the high-tech jobs. The exact list of STEM fields does not exist; it varies from organization to organization.'
        }
    ]
    singleBookFeatures: FeaturesContent[] = [
        {
            icon: 'las la-flask',
            title: 'Science',
            paragraph: 'The “S” in STEM typically applies to natural sciences and formal sciences, the former including chemistry, biology, physics and environmental science, while the latter focuses more on mathematics and statistics.'
        },
        {
            icon: 'las la-microchip',
            title: 'Technology',
            paragraph: 'The field of technology is focused on the production of goods or accomplishment of objectives. Occupations in computer and information technology are expected to grow 12 percent over the next decade.'
        },
        {
            icon: 'las la-robot',
            title: 'Engineering',
            paragraph: 'Engineering covers a broad range of specialized fields that relate to the designing and building of items such as machines, buildings, roads and other structures using scientific principles.'
        },
        {
            icon: 'las la-square-root-alt',
            title: 'Mathematics',
            paragraph: 'While mathematics has no generally accepted definition, almost all STEM fields involve math to some degree. Fields include arithmetic, the study of numbers and the properties of operations on them; geometry, concerned with the shape, size and position of figures; and algebra, the study of mathematical symbols.'
        },
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class FeaturesContent {
    icon : string;
    title : string;
    paragraph : string;
}