<section id="pricing" class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singlePricingBox;">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>{{Content.title}}</h3>
                    </div>
                    <div class="price">
                        {{Content.price}}
                    </div>
                    <p>{{Content.paragraph}}</p>
                    <div class="pricing-btn">
                        <a href="{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>