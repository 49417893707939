import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-book-contact',
    templateUrl: './book-contact.component.html',
    styleUrls: ['./book-contact.component.scss']
})
export class BookContactComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Contact us',
            paragraphText: 'Contact us to learn more about how you can apply to the Jayuya STEM scholarship.'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact-us.png'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}