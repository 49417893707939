import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-book-about',
    templateUrl: './book-about.component.html',
    styleUrls: ['./book-about.component.scss']
})
export class BookAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/book-about.png'
        }
    ]
    aboutContent = [
        {
            title: 'Careers in STEM',
            paragraphList: [
                {
                    paragraph: "Science, technology, engineering, and mathematics (STEM) programs open the door to many different careers, including opportunities in engineering, science, and research. STEM professionals can explore diverse fields like aeronautics and biochemistry. Some STEM students also apply their technical knowledge in law, politics, and education.",
                },
                {
                    paragraph: "There are dozens of STEM disciplines and professional fields recruiting students with strong math and science skills. The following section covers some of the most common STEM programs. Most of these fields offer opportunities for greater specialization, particularly for students in graduate programs.",
                }
            ],
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "Astronomy"
                },
                {
                    itemIcon: "las la-check",
                    item: "Biology"
                },
                {
                    itemIcon: "las la-check",
                    item: "Chemistry"
                },
                {
                    itemIcon: "las la-check",
                    item: "Computer Science"
                },
                {
                    itemIcon: "las la-check",
                    item: "Electrical Engineering"
                },
                {
                    itemIcon: "las la-check",
                    item: "Geology"
                },
                {
                    itemIcon: "las la-check",
                    item: "Information Technology"
                },
                {
                    itemIcon: "las la-check",
                    item: "Applied Math"
                },
                {
                    itemIcon: "las la-check",
                    item: "Mechanical Engineering"
                },
                {
                    itemIcon: "las la-check",
                    item: "Physics"
                },
                {
                    itemIcon: "las la-check",
                    item: "Biomedical Engineering"
                },
                {
                    itemIcon: "las la-check",
                    item: "Chemical Engineering"
                }
            ]
        }
    ];

}
class Image {
    img : string;
}