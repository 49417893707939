import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-banner',
  templateUrl: './book-banner.component.html',
  styleUrls: ['./book-banner.component.scss']
})
export class BookBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    bannerContent = [
        {
            subTitle: '',
            title: 'Jayuya STEM',
            paragraphList: [
                {
                    text: "The Jayuya STEM scholarship team is composed of professionals in STEM fields that want to donate their time to mentor and assist students who participate in the program."
                },
                {
                    text: "Students from Jayuya, PR can apply to a $1000/semester scholarship when pursuing a career in a STEM field and maintain a 3.5 GPA average"
                }
            ],
            buttonText: 'Buy Now for $9.30',
            buttonLink: '#',
            social: [
                {
                    icon: "lab la-facebook-f",
                    link: "https://www.facebook.com/JayuyaStemScholarship"
                },
                {
                    icon: "lab la-twitter",
                    link: "https://www.twitter.com/jayuyastem"
                },
            ]

        }
    ];
    bannerImage = [
        {
            img: 'assets/img/scholarship-banner.png'
        }
    ];

}