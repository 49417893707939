import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-book-author',
    templateUrl: './book-author.component.html',
    styleUrls: ['./book-author.component.scss']
})
export class BookAuthorComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'About the founder',
            paragraphText: 'Jayuya STEM was founded by Juan Rivera with the goal to help other fellow Jayuyanos in their pursuit of careers in the field of STEM.'
        }
    ]
    authorContent = [
        {
            title: 'Juan Rivera',
            paragraphList: [
                {
                    paragraph: "Juan is currently SVP Engineering for Citrix Systems. He leads an engineering organization of over 1500 engineers across the globe including offices in the USA, UK, Czech Republic, Grece, India and China.",
                },
                {
                    paragraph: "He holds a masters degree from the Rochester Institure of Technology in Software Engineering Management. And a bachelors degree from the University of Puerto Rico in Computer Engineering.",
                }
            ],
            social: [
                {
                    icon: "lab la-twitter",
                    link: "https://twitter.com/juancitrix"
                },
                {
                    icon: "lab la-linkedin-in",
                    link: "https://linkedin.com/in/juancrivera"
                },
            ]
        }
    ];
    authorImage = [
        {
            img: 'assets/img/author/author.jpg'
        }
    ];

}