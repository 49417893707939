import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { BookLandingComponent } from './components/pages/book-landing/book-landing.component';
import { BookBannerComponent } from './components/pages/book-landing/book-banner/book-banner.component';
import { BookFeaturesComponent } from './components/pages/book-landing/book-features/book-features.component';
import { BookAboutComponent } from './components/pages/book-landing/book-about/book-about.component';
import { BookFunfactsComponent } from './components/pages/book-landing/book-funfacts/book-funfacts.component';
import { BookPricingComponent } from './components/pages/book-landing/book-pricing/book-pricing.component';
import { BookNewsletterComponent } from './components/pages/book-landing/book-newsletter/book-newsletter.component';
import { BookFeedbackComponent } from './components/pages/book-landing/book-feedback/book-feedback.component';
import { BookContactComponent } from './components/pages/book-landing/book-contact/book-contact.component';
import { BookAuthorComponent } from './components/pages/book-landing/book-author/book-author.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    BookLandingComponent,
    FooterComponent,
    BookBannerComponent,
    BookFeaturesComponent,
    BookAboutComponent,
    BookFunfactsComponent,
    BookPricingComponent,
    BookNewsletterComponent,
    BookFeedbackComponent,
    BookContactComponent,
    BookAuthorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
